import {Http} from '@wix/wixstores-client-core/dist/es/src/http/http';
import {gqlQuery} from './getProduct';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {query as getCartServiceQuery} from '../graphql/getCartService.graphql';
import {graphqlOperation} from '../constants';
import {GetCartServiceQuery} from '../graphql/queries-schema';
import {VolatileCartFromServer} from './volatileCartService.testKit';
import {SPECS} from '../specs';
import {CheckoutApiService} from './checkout-api-service';
import {CheckoutType} from './DirectPurchaseService';

export const VolatileCartApiUrl = '/stores/v1/carts/volatileCart';

export class VolatileCartService {
  private _cartId: string;
  private _initialPartialCart: VolatileCartFromServer['cart'];
  private readonly checkoutApiService: CheckoutApiService;

  constructor(private readonly httpClient: Http, private readonly siteStore: SiteStore) {
    this.checkoutApiService = new CheckoutApiService(siteStore);
  }

  /* istanbul ignore next: experiment always on in test env */
  public shouldUseCheckoutNewApi(experimentName?: string) {
    if (this.siteStore.experiments.enabled(SPECS.USE_CART_COMMANDS_OF_CHECKOUT_FROM_NODE)) {
      return experimentName ? this.siteStore.experiments.enabled(experimentName) : true;
    } else {
      return false;
    }
  }

  public getStandaloneCartId = async (
    productId: string,
    optionSelectionId: number[],
    quantity: number,
    customTextFieldSelection: {title: string; value: string}[],
    checkoutType: CheckoutType,
    subscriptionOptionId?: string
  ): Promise<string> => {
    if (
      checkoutType === CheckoutType.BuyNow &&
      this.shouldUseCheckoutNewApi(SPECS.MIGRATE_VOLATILE_CART_API_TO_GRAPHQL_NODE)
    ) {
      const response = await this.checkoutApiService.createCart({
        productId,
        optionSelectionId,
        customTextFieldSelection,
        quantity,
        subscriptionOptionId,
      });
      this._cartId = response.id;
      this._initialPartialCart = response as VolatileCartFromServer['cart'];
      return this._cartId;
    } else {
      const response = await this.httpClient.post(VolatileCartApiUrl, {
        productId,
        optionSelectionId,
        customTextFieldSelection,
        quantity,
        subscriptionOptionId,
      });
      this._cartId = response.data.cart.id;
      this._initialPartialCart = response.data.cart;
      return this._cartId;
    }
  };

  public get initialPartialCart() {
    return this._initialPartialCart;
  }

  public getCart = async (): Promise<GetCartServiceQuery> => {
    return (
      await gqlQuery(
        this.siteStore,
        getCartServiceQuery,
        {cartId: this._cartId, locale: this.siteStore.locale},
        graphqlOperation.GetCartService
      )
    ).data;
  };

  public get cartId() {
    return this._cartId;
  }
}
